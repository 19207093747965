import IndexLayout from '../layouts';
import Wrapper from '../components/Wrapper';
import SiteNav from '../components/header/SiteNav';
import { SiteHeader, outer, inner, SiteMain } from '../styles/shared';
import * as React from 'react';
import { css } from '@emotion/core';

import { PostFullHeader, PostFullTitle, NoImage, PostFull } from '../templates/post';
import { PostFullContent } from '../components/PostContent';
import Footer from '../components/Footer';
import Helmet from 'react-helmet';

const PageTemplate = css`
  .site-main {
    background: #fff;
    padding-bottom: 4vw;
  }
`;

const Impressum: React.FC = () => (
  <IndexLayout>
    <Helmet>
      <title>Impressum</title>
    </Helmet>
    <Wrapper css={PageTemplate}>
      <header css={[outer, SiteHeader]}>
        <div css={inner}>
          <SiteNav />
        </div>
      </header>
      <main id="site-main" className="site-main" css={[SiteMain, outer]}>
        <article className="post page" css={[PostFull, NoImage]}>
          <PostFullHeader>
            <PostFullTitle>Impressum</PostFullTitle>
          </PostFullHeader>

          <PostFullContent className="post-full-content">
            <div className="post-content">
              <h3>Angaben gemäß § 5 TMG</h3>
              <p>
                Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV <br />
                Professur für Didaktik der Informatik <br />
                Freie Universität Berlin <br />
                Königin-Luise-Str. 24-26 <br />
                14195 Berlin
                <br />
                Telefon: +49 30 838 58727 <br />
                E-Mail: kate.wegner@inf.fu-berlin.de
              </p>
              <h3>Nutzungsbedingungen</h3>
              <p>
                Texte, Bilder, Grafiken sowie die Gestaltung dieser Internetseiten können dem
                Urheberrecht unterliegen.
              </p>
              <p>
                Nicht urheberrechtlich geschützt sind nach § 5 des Urheberrechtsgesetz (UrhG)
                Gesetze, Verordnungen, amtliche Erlasse und Bekanntmachungen sowie Entscheidungen
                und amtlich verfasste Leitsätze zu Entscheidungen und andere amtliche Werke, die im
                amtlichen Interesse zur allgemeinen Kenntnisnahme veröffentlicht worden sind, mit
                der Einschränkung, dass die Bestimmungen über Änderungsverbot und Quellenangabe in §
                62 Abs. 1 bis 3 und § 63 Abs. 1 und 2 UrhG entsprechend anzuwenden sind. Als
                Privatperson dürfen Sie urheberrechtlich geschütztes Material zum privaten und
                sonstigen eigenen Gebrauch im Rahmen des § 53 Urheberrechtsgesetz (UrhG) verwenden.
                Eine Vervielfältigung oder Verwendung dieser Seiten oder Teilen davon in anderen
                elektronischen oder gedruckten Publikationen und deren Veröffentlichung ist nur mit
                unserer Einwilligung gestattet. Diese erteilen auf Anfrage die für den Inhalt
                Verantwortlichen. Der Nachdruck und die Auswertung von Pressemitteilungen und Reden
                sind mit Quellenangabe allgemein gestattet.
              </p>
              <p>
                Weiterhin können Texte, Bilder, Grafiken und sonstige Dateien ganz oder teilweise
                dem Urheberrecht Dritter unterliegen. Auch über das Bestehen möglicher Rechte
                Dritter geben Ihnen die für den Inhalt Verantwortlichen nähere Auskünfte, sofern
                diese Rechte nicht bereits durch entsprechende Hinweise im Webangebot kenntlich
                gemacht sind.
              </p>
              <h3>Haftungsausschluss</h3>
              <p>
                Alle auf dieser Internetseite bereitgestellten Informationen haben wir nach bestem
                Wissen und Gewissen erarbeitet und geprüft. Eine Gewähr für die jederzeitige
                Aktualität, Richtigkeit, Vollständigkeit und Verfügbarkeit der bereit gestellten
                Informationen können wir allerdings nicht übernehmen. Ein Vertragsverhältnis mit den
                Nutzern des Internetangebots kommt nicht zustande.
              </p>
              <p>
                Wir haften nicht für Schäden, die durch die Nutzung dieses Internetangebots
                entstehen. Dieser Haftungsausschluss gilt nicht, soweit die Vorschriften des § 839
                des Bürgerlichen Gesetzbuches (Haftung bei Amtspflichtverletzung) einschlägig sind.
                Für etwaige Schäden, die beim Aufrufen oder Herunterladen von Daten durch
                Schadsoftware oder der Installation oder Nutzung von Software verursacht werden,
                wird nicht gehaftet. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
                Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8
                bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
                gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die
                auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder
                Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon
                unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis
                einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
                Rechtsverletzungen werden wir diese Inhalte umgehend entfernen. Der Betreiber behält
                es sich ausdrücklich vor, einzelne Webseiten, Web-Dienste oder das gesamte Angebot
                ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die
                Veröffentlichung zeitweise oder endgültig einzustellen.
              </p>
              <h3>Haftung für Links</h3>
              <p>
                Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir
                keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine
                Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige
                Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum
                Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
                Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente
                inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte
                einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen
                werden wir derartige Links umgehend entfernen.
              </p>
            </div>
          </PostFullContent>
        </article>
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
);

export default Impressum;
